import { defineStore } from "pinia"
import { api } from '@/plugins/axios'
import { useNotifyStore } from '@/stores/notify'
import { useRouter } from 'vue-router'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    profile: null,
    jwt: '',
    loggingIn: false
  }),
  getters: {
    isAuthenticated: (state) => !!state.jwt && !!state.user,
    profileIsLoaded: (state) => state.profile
  },
  actions: {
    init: function() {
      const router = useRouter()

      try {
        const storedUser = localStorage.getItem('js-user')
        const storedJwt = localStorage.getItem('js-jwt')

        api.defaults.headers.common.Authorization = 'Bearer ' + storedJwt

        this.jwt = storedJwt
        this.user = JSON.parse(storedUser)
      } catch (error) {
        console.error(error)
        router.push('/auth/login')
      }
    },

    login: async function(email, password) {
      const notify = useNotifyStore()

      // Make sure no token is being used when logging in
      delete api.defaults.headers.common.Authorization

      try {
        const response = await api.post('auth/local', {
          identifier: email,
          password
        })

        this.user = response.data.user
        this.jwt = response.data.jwt

        api.defaults.headers.common.Authorization = 'Bearer ' + response.data.jwt
        localStorage.setItem('js-user', JSON.stringify(response.data.user))
        localStorage.setItem('js-jwt', response.data.jwt)
        this.$router.push('/')
      } catch (error) {
        console.log(error);
        notify.error()
      } finally {
        console.log('Done logging in');
      }
    },

    logout: function() {
      this.$reset()
      delete api.defaults.headers.common.Authorization
      localStorage.removeItem('js-user')
      localStorage.removeItem('js-jwt')
      this.$router.push('/auth/login')
    },

    loadUser: async function() {
      const notify = useNotifyStore()

      try {
        const { data } = await api.get('users/me?populate=*')

        this.profile = data
      } catch (error) {
        console.error(error)
        notify.error('Det ser ud til at dit login er udløbet. Log venligst ind igen.')
        this.logout()
      }
    }
  }
})