import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '../stores/auth'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('../layouts/MainLayout.vue'),
      meta: { requiresAuthentication: true },
      children: [
        {
          path: '',
          name: 'order',
          component: () => import('../views/OrderView.vue')
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('../views/AboutView.vue')
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import('../views/ProfileView.vue')
        },
      ]
    },
    {
      path: '/auth',
      component: () => import('../layouts/AuthLayout.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('../views/LoginView.vue')
        },
        {
          path: 'forgot-password',
          name: 'forgot-password',
          component: () => import('../views/ForgotPasswordView.vue')
        },
        {
          path: 'reset-password',
          name: 'reset-password',
          component: () => import('../views/ResetPasswordView.vue')
        }
      ]
    }
  ]
})

router.beforeEach((to, from) => {
  const authStore = useAuthStore()
  if (!to.meta.requiresAuthentication && authStore.isAuthenticated) {
    console.log('User is already authenticated');
    return '/'
  }
  
  if (to.meta.requiresAuthentication && !authStore.isAuthenticated) {
    return '/auth/login'
  }
})

export default router
