<script setup>
import { RouterView } from 'vue-router'
import { useAuthStore } from './stores/auth'
import { onBeforeMount } from 'vue'
import Notification from './components/Notification.vue'

const authStore = useAuthStore()

onBeforeMount(() => {
  authStore.init()
})
</script>

<template>
  <v-app>
    <RouterView />
    <Notification />
  </v-app>
</template>
