<script setup>
import { useNotifyStore } from '../stores/notify'

const notify = useNotifyStore()
</script>

<template>
  <v-snackbar
    v-model="notify.show"
    :color="notify.color"
    transition="slide-y-reverse-transition"
    :timeout="notify.timeout"
  >
    <template #default>
      <div class="d-flex align-center">
        <v-icon v-if="notify.icon" class="mr-3"> {{ notify.icon }} </v-icon>
        <span> {{ notify.message }} </span>
      </div>
    </template>

    <template #actions>
      <v-btn text="Luk" @click="notify.dismiss"></v-btn>
    </template>
  </v-snackbar>
</template>