import { defineStore } from "pinia";

const defaultSuccessMessage = 'Handlingen blev gennemført'
const defaultErrorMessage = 'Der skete en fejl'

export const useNotifyStore = defineStore('notify', {
  state: () => ({
    show: false,
    message: '',
    color: 'default',
    icon: '',
    timeout: 5000
  }),
  actions: {
    custom(message = '', color = '', icon = '', timeout ) {
      this.message = message
      this.color = color
      this.icon = icon

      if (timeout) {
        this.timeout = timeout
      }

      this.show = true
    },
    saved() {
      this.success('Ændringerne blev gemt')
    },
    success(message = defaultSuccessMessage, icon = 'mdi-check', color = 'success', timeout) {
      if (message === defaultSuccessMessage) {
        console.warn('Showed a success notification with the default message. A custom message should be used.')
      }

      this.custom(message, color, icon, timeout)
    },
    error(message = defaultErrorMessage, color = 'error', icon = 'mdi-alert-circle-outline', timeout) {
      this.custom(message, color, icon, timeout)
    },
    warning(message, color = 'warning', icon = 'mdi-alert-circle-outline', timeout) {
      if (!message) {
        console.log('Tried to display a warning but no message defined')
        return
      }

      else {
        this.custom(message, color, icon, timeout)
      }
    },
    dismiss() {
      this.show = false
    }
  }
})